import request from '@/request/http'
import base from '@/request/base'
export default {
	// 列表
	tracklist(params) {
		return request({
			url: `${base.url}/track/tracking`,
			// url: `http://192.168.3.90:9020/track/tracking`,
			method: 'post',
			data: params,
		})
	},
	// 物流商列表
	carriers(params) {
		return request({
			url: `${base.url}/track/carriers`,
			method: 'get',
			header: {
				'Content-Type': 'application/json',
			},
			data: params,
		})
	},
}
